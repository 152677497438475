import React from 'react';
import testimonialQuotes from '../img/testimonials-quotes.svg';

const TestimonialSection = ({ testimonial }) => (
  <div className='full-width testimonial-section'>
    <div className='container content testimonial-content'>
      <div className='has-text-centered'>
        <div className='review-text'>"{testimonial.review}"</div>
        <h4 className='review-name'>{testimonial.name}</h4>
      </div>
    </div>
    <div className='watermark quotation-mark'>
      <img src={testimonialQuotes} />
    </div>
  </div>
);

export default TestimonialSection;
