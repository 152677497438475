import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import HeroSlider from '../../components/HeroSlider';
import DualSlider from '../../components/DualSlider';
import PortfolioTiles from '../../components/PortfolioTiles';
import ProjectFoot from '../../components/ProjectFoot';
import PageHelmet from '../../components/PageHelmet';
import Layout from '../../components/Layout';
import TestimonialSection from '../../components/TestimonialSection';
import InformationSection from '../../components/InformationSection';
import useSiteMetadata from '../../components/SiteMetadata';
import MetaComponent from '../../components/MetaComponent';

export const ProjectManagementTemplate = ({
  heroImages,
  informationSection,
  isPreview,
  seoObject,
  dualslider1,
  dualslider2,
  projectFoot,
  testimonial,
  helmet,
}) => (
  <>
    {helmet || ''}
    <PageHelmet seoObject={seoObject} />
    <HeroSlider heroImages={heroImages} heroTitle='' />
    <InformationSection informationSection={informationSection} />
    <DualSlider slides={dualslider1} triangle='top-right' />
    <TestimonialSection testimonial={testimonial} />
    <DualSlider slides={dualslider2} triangle='top-left' />
    <PortfolioTiles locale='en' isPreview={isPreview} />
    <ProjectFoot projectFoot={projectFoot} />
  </>
);

const ProjectManagementPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { title, siteUrl } = useSiteMetadata();
  return (
    <Layout>
      <ProjectManagementTemplate
        key='project-management-page'
        heroImages={frontmatter.heroImages}
        informationSection={frontmatter.informationSection}
        location={location}
        seoObject={frontmatter.seoObject}
        testimonial={frontmatter.testimonial}
        dualslider1={frontmatter.dualslider_one}
        dualslider2={frontmatter.dualslider_two}
        projectFoot={frontmatter.projectFoot}
        helmet={
          <MetaComponent
            title={`${frontmatter.seoObject.metaTitle}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${frontmatter.seoObject.metaDescription}`}
            link={`${siteUrl}${location.pathname}`}
            image={`${frontmatter.heroImages
              .slice(0, 1)
              .map((item) => item.image)}`}
          />
        }
      />
    </Layout>
  );
};

ProjectManagementPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProjectManagementPage;

export const pageQuery = graphql`
  query ProjectManagementPageQueryZHHANS {
    markdownRemark(
      frontmatter: { templateKey: { eq: "project-management-page-zhhans" } }
    ) {
      frontmatter {
        seoObject {
          metaTitle
          metaDescription
        }
        heroImages {
          image
          overlay
          heroTitle
          heroSubtitle
          animation
          position
          buttonObject {
            buttonText
            linkObject {
              link
              isExternal
            }
          }
        }
        dualslider_one {
          dualsliderTitle
          slides {
            title
            image
            text
          }
        }
        testimonial {
          review
          name
        }
        dualslider_two {
          dualsliderTitle
          slides {
            title
            image
            text
          }
        }
        informationSection {
          color
          textItems {
            title
            text
          }
        }
        projectFoot {
          title
          text
          image
          overlay
          email
        }
      }
    }
  }
`;
