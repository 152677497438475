import React from 'react'

const InformationSection = ({ informationSection }) => {
  const getContainerStyle = () => {
    if (informationSection.color === 'black') {
      return ({
        backgroundColor: 'black',
        color: 'white'
      });
    }
    return null;
  }

  const textStyle = informationSection.color === 'black' ? ({ color: 'white'}) : null;
  const hrStyle = informationSection.color === 'black' ? ({ backgroundColor: 'white' }) : null;
  const numberStyle = informationSection.color === 'black' ? ({ color: '#1e1e1e' }) : null;
  const textContainer = informationSection.color === 'black' ? ({ zIndex: 1 }) : null;

  const mappedPageComponents = informationSection.textItems.map((component, index) => (
    <div key={component.title} className="column is-4 information-item">
      <h2 className="title services-title" style={  textStyle }>
        {component.title}
        <span style={ numberStyle } className="background-number">{`0${index + 1}`}</span>
      </h2>
      <hr className="hr-black" style={ hrStyle } />
      <div>{component.text}</div>
    </div>
  ));
 
  return (
    <div className="full-width" style={ getContainerStyle() }>
      <div className="section">
        <div className="container content information-section" style={ textContainer }>
          <div className="columns is-variable is-6">
            { mappedPageComponents }
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationSection;
