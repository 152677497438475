import React from 'react'
import BackgroundImage from './BackgroundImage'
import { ImageOverlay } from '../utils'
import { sliderStyles } from '../utils'

const ProjectFoot = ({ projectFoot }) => {
  return (
    <div className="project-foot">
      <BackgroundImage classes={"project-foot-image hero-slide-image vertically-centered"} imageInfo={projectFoot.image}>
            <div
              style={sliderStyles["slide-center-center"]}
              className="container content hero-slide-content"
            >
              <div>
                <h2 className="project-foot-title">{ projectFoot.title }</h2>
                <div className="project-foot-text"><a href={`mailto:${projectFoot.email}`}>{projectFoot.text}</a></div>
              </div>
            </div>
        <ImageOverlay overlay={projectFoot.overlay} />
      </BackgroundImage>
    </div>
  )
}

export default ProjectFoot;